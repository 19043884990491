<template>
  <div class="preview dark"
    v-loading="loading"
    element-loading-text="加载中..."
    :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="preview-header">
      <div class="breadcrumb-head">
        <el-breadcrumb separator="/" >
          <el-breadcrumb-item
            v-for="(n, i) in project.routes"
            :key="i">
            <span v-if="n.disable" style="cursor: pointer; color: #1eaaff" @click="jumpSpace(n)">
            {{n.name}}</span>
            <span v-else style="color: #b3bfc7">
            {{n.name}}</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="preview-body2">
      <el-scrollbar>
        <div class="frame common-frame">
          <div class="line"
               style="padding:24px 0 16px">
            <div class="right">
              <el-button type="primary"
                         @click="globalParametersCreate()" v-if="addAuth">新增数据</el-button>
              <el-button type="danger"
                         v-show="multipleSelection.length">批量删除</el-button>
            </div>
          </div>
          <el-table :data="tableData"
                    style="width: 100%"
                    @selection-change="handleSelectionChange">
            <el-table-column 
                             min-width="5%" />
            <el-table-column label="参数名"
                             prop="key"
                             min-width="45%"></el-table-column>
            <el-table-column label="变量值"
                             prop="value"
                             min-width="40%" />
            <el-table-column label="操作"
                             min-width="10%">
              <template #default="scope">
                <el-button link
                           size="small"
                           @click.prevent="globalParametersEdit(scope.$index, tableData)" v-if="editAuth">
                  编辑
                </el-button>
                <el-button link
                           size="small"
                           @click.prevent="globalParametersDel(scope.$index, tableData)" v-if="delAuth">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="line pd-10">
            <page :total="conf.total" v-show="tableData.length" @onChange="onChange"></page>
          </div>
        </div>
      </el-scrollbar>
    </div>

    <window windowId="edit-data-source"
            :shadow="true"
            class="dark"
            :title="globalParameters.title"
            @windowHide="globalParametersHide"
            v-show="globalParameters.isShow">
      <template #body>
        <div class="line pd-20">
          <el-form ref="form" :model="globalParameters.info" :rules="rules">
            <el-form-item prop="key">
               <div class="insert-h">
                  <div class="attr">参数名</div>
                  <div class="val">
                    <el-input v-model="globalParameters.info.key"
                              placeholder="请输入参数名" />
                  </div>
                </div>
            </el-form-item>
            <el-form-item prop="value">
                 <div class="insert-h">
                    <div class="attr">变量值</div>
                    <div class="val">
                      <el-input v-model="globalParameters.info.value"
                                placeholder="请输入变量值" />
                    </div>
                  </div>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <template #footer>
        <div class="line pd-20 align-right">
          <el-button @click="globalParametersHide()">取消</el-button>
          <el-button type="primary"
                     @click="globalParametersSave()">保存</el-button>
        </div>
      </template>
    </window>
  </div>
</template>

<script>
import Window from 'components/common/forms/Window';
import Page from 'components/common/forms/Page';
import Empty from 'components/common/forms/Empty';
import { prjBtnStatus, getSpaceOrPrjDetails, jumpRoute } from 'common/authBtn';
import { mapMutations } from 'vuex';
export default {
  name: 'GlobalVariable',
  components: {
    Window,
    Page,
    Empty
  },
  data () {
    return {
      loading: true,
      rules: {
        key: [{required: true, message: '参数名不能为空', trigger: 'blur'}],
        value: [{required: true, message: '变量值不能为空', trigger: 'blur'}],
      },
      project: {},
      addAuth: false,
      editAuth: false,
      delAuth: false,
      tableData: [],
      multipleSelection: [],
      globalParameters: {
        isShow: false,
        isEdit: false,
        title: "",
        info: {
          key: '',
          value: '',
        }
      },
      conf: {
        pageSize: 10,
        pageNum: 1,
        total: 10
      },
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    ...mapMutations({
      setGlobalValues: 'Global/setGlobalValues'
    }),
    getDetail() {
      let self = this;
      
      getSpaceOrPrjDetails(self.$store).then((res) => {
        self.loading = false;
        self.project = res;
        prjBtnStatus(res.id, self.$store, (result) => {
          self.addAuth = result.addAuth;
          self.editAuth = result.editAuth;
          self.delAuth = result.delAuth;
        });
        self.getDataFn();
      })
    },
    getDataFn() {
      let self = this;
      self.$api.sp.getGlobalByPage({
        prjId: self.project.id,
        pageNum: self.conf.pageNum,
        pageSize: self.conf.pageSize
      }).then((res) => {
        self.tableData = res.list;
        self.setGlobalValues(self.tableData);
        self.conf.total = res.total;
      })
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    globalParametersCreate () {
      this.initForm();
      let ds = this.globalParameters;
      ds.title = "创建全局参数";
      ds.isEdit = false;
      ds.info = {
        prjId: this.project.id,
        key: '',
        value: '',
      }
      ds.isShow = true;
    },
    globalParametersEdit (i, list) {
      this.initForm();
      let ds = this.globalParameters;
      ds.title = "编辑全局参数";
      ds.isEdit = true;
      ds.info = this.deepClone(list[i]);
      ds.isShow = true;
    },
    globalParametersDel (i, list) {
      let self = this;
      self
        .$confirm("此操作将永久删除该变量, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          self.$api.sp.deleteGlobal({ id: list[i].id }).then((res) => {
            self.$message.success( "删除成功!");
            self.getDataFn();
          });
        });
    },
    globalParametersHide () {
      this.globalParameters.isShow = false;
    },
    globalParametersSave () {
      let self = this;
      self.$refs.form.validate(valid => {
        if(valid) {
          if(!self.globalParameters.isEdit) {
            self.$api.sp.addGlobal(self.globalParameters.info).then((res) => {
              if(res) {
                self.$message.success('操作成功');
                self.getDataFn();
                self.globalParametersHide();
              }
            })
          }else {
            self.$api.sp.updateGlobal(self.globalParameters.info).then((res) => {
              if(res) {
                self.$message.success('操作成功');
                self.getDataFn();
                self.globalParametersHide();
              }
            })
          }
        }
      }) 
    },
    onChange (param) {
      this.conf.pageSize = param.pageSize;
      this.conf.pageNum = param.currentPage;
      this.getDataFn();
    },
    jumpSpace(n) {
      let self = this;
      if(self.project.id != n.id) {
       jumpRoute(self, n).then(() => {
         self.$router.push('/Main/SpaceDetail');
       }) 
      }
    },
    initData() {
      this.loading = true;
      this.tableData = [];
      this.conf= {
        pageSize: 10,
        pageNum: 1,
        total: 10
      };
    },
    initForm () {
      this.$refs.form.clearValidate();
    },
  }
}
</script>

<style lang="scss" scoped>
</style>